<template>
  <div>
    <b-table
        ref="sms-table"
        striped
        hover
        responsive
        v-bind:items="messages"
        v-bind:fields="fields"
        v-bind:sort-by.sync="sort.by"
        v-bind:sort-desc.sync="sort.desc"
        v-bind:sort-direction="sort.direction"

    >
      <template #cell(clientId)="data">
        <div v-if="clients[data.value] != null">
          <b-link @click="onClientSelect(clients[data.value].id)">{{ clients[data.value].firstname }}
            {{ clients[data.value].lastname }}
          </b-link>
        </div>
        <div v-else>
          <b-badge variant="warning">{{ msg('Anonymous') }}</b-badge>
        </div>
      </template>


      <template #cell(sendDate)="data">
        {{ $moment(data.value).format("DD/MM/YY HH:mm") }}
      </template>
      </b-table>

    <div class="text-center w-100">
      <div class="d-inline-block">
        <b-pagination
            v-model="page.currentPage"
            :total-rows="page.total"
            :per-page="page.amount"
            first-number
            last-number
            @input="refresh"
        />
      </div>
      <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'smsTable',
  data() {
    return {
      messages: [],
      clients: {},
      sort: { by: 'creationDate', direction: 'desc', },
      page: { amount: 10, currentPage: 1, total: 0 },

      //Listeners
      listeners: {
        refresh: () => {
        }
      }
    }
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    fieldsFilter: {
      default: () => [],
    }
  },
  watch: {
    ['page.amount']() {
      if (this.page.amount !== this.amount) {
        this.$emit('update:amount', this.page.amount)
      }

    },
    amount() {
      if (this.page.amount === this.amount) {
        return
      }
      this.page.amount = this.amount
      this.refresh()
    },
  },
  created() {
    let $this = this
    this.refresh()
    this.$root.$on('crm::sms-table::refresh', $this.listeners.refresh = () => {
      $this.refresh()
    })
  },
  beforeDestroy() {
    this.$off('crm::sms-table::refresh', this.listeners.refresh)
  },
  computed: {
    ...mapGetters('data', ['getBranchName']),
    fields() {
      let $this = this
      let fields = [

        {
          key: 'clientId',
          label: $this.msg('Client'),
          sortable: false
        }
        ,
        {
          key: 'message',
          label: $this.msg('Message'),
          sortable: false
        },
        {
          key: 'sendDate',
          label: $this.msg('SendDate'),
          sortable: true
        }
      ]
      if (!Array.isArray(this.fieldsFilter) || this.fieldsFilter.length === 0) {
        return fields
      }
      return fields.filter(f => $this.fieldsFilter.includes(f.key))
    },
  },
  methods: {
    ...mapActions('sms', ['getAllSms']),
    ...mapActions('clients', ['getClients']),

    refresh() {
      let $this = this
      this.getAllSms({
        page: $this.page.currentPage,
        amountPerPage: $this.page.amount,
        filter: $this.filter,
        sortby: this.sort.direction,
        sortcolumn: this.sort.by
      })
          .then($this.onMessagesRetrieve)
    },
    onMessagesRetrieve(smsData) {
      this.messages = smsData.messages
      this.page = {
        amount: smsData['amount'],
        total: smsData['total'],
        currentPage: smsData['page']
      }
      this.retrieveClients(smsData.messages)
    },
    retrieveClients(messages) {
      let $this = this
      let ids = messages.map((message) => message.clientId)
      this.getClients({
        page: 1,
        amountPerPage: -1,
        filter: { ids }
      })
          .then((clientData) => $this.clients = clientData.clients.toObject('id'))
    },
    onClientSelect(clientId) {
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select', clientId)
    },

  }
}
</script>

<style scoped>

</style>
