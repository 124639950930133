<template>
  <div class="">
<!--    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg('Filters') }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>

    </b-card>-->
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Client sms logs') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="()=>$refs['sms-table'].refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                      v-model="amountPerPage"
                      :dir="isRTL ? 'rtl' : 'ltr'"
                      :options="[10, 25, 50, 100]"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50"
                      @input="(amount)=>$refs['sms-table'].updatePageAmount(amount)"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
          </b-row>
          <sms-table ref="sms-table" v-bind:filter="filter"/>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>


<script>
  import { mapActions, mapGetters } from 'vuex'
  import SmsTable from "@/views/client-sms/sms-table";

  export default {
    components: {
      SmsTable,
    },
    name: 'clientSms',
    data: () => ({
      filter: {fromDate:null,untilDate:null},
      amount:10,
      amountPerPage: 10,
      items:[],
    }),
    watch: {
      filter: {
        deep: true,
        handler() {
        }
      },
    },
    created() {
    },
    computed: {
      ...mapGetters('data', ['getUserFullName', 'getAllUsers', 'getUser']),
      creationDatePicker: {
        get() {
          let $this = this
          return {
            start: $this.filter.gte.creationDate,
            end: $this.filter.lte.creationDate
          }
        },
        set(v) {
          console.log('creationDatePicker', v)
          if (v == null) {
            this.filter.gte.creationDate = null
            this.filter.lte.creationDate = null
          }
          this.filter.gte.creationDate = v.start
          this.filter.lte.creationDate = v.end
        }
      },
      allUsers() {
        let $this = this
        return this.getAllUsers.map(user => ({
          id: user.id,
          fullName: $this.getUserFullName(user.id),
        }))
      },
      creationDate:{
        get(){
          return {start:this.filter.fromDate,end:this.filter.untilDate}
        },
        set(v){
          this.filter.fromDate = v.start;
          this.filter.untilDate = v.end;
        }
      }
    },
    methods: {
      refresh(){
        this.$root.$emit("crm::sms-table::refresh");
      },

      rangeToStr(dateRange){
        if(dateRange.start == null)
          return "";
        if(dateRange.end == null)
          return this.$moment(dateRange.start,'YYYY-MM-DD').format('DD/MM/YYYY');
        return this.$moment(dateRange.start,'YYYY-MM-DD').format('DD/MM/YYYY') + " - " + this.$moment(dateRange.end,'YYYY-MM-DD').format('DD/MM/YYYY');
      }
    }
  }
</script>

<style scoped>

</style>
